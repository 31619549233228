import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios, { AxiosError } from 'axios';
import api from "../api/axiosInstance";

const Container = styled.div`
    padding: 20px;
    position: relative;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const BackButton = styled.button`
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
`;

const Heading = styled.h2`
    margin: 0;
    font-size: 24px;
`;

const Label = styled.label`
    margin-bottom: 10px;
    display: block;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
`;

const SaveButton = styled.button`
    background-color: #242526;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;

    &:hover {
        background-color: #3a3b3c;
    }
`;

const ErrorMessage = styled.div`
    color: red;
    margin-top: -15px;
    margin-bottom: 15px;
`;

const ChangePassword: React.FC = () => {
    const navigate = useNavigate();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [error, setError] = useState('');

    const handleSave = async () => {
        if (newPassword !== repeatPassword) {
            setError('Heslá sa nezhodujú');
            return;
        }

        try {
            const response = await api.put('/account/change-password', {
                currentPassword,
                newPassword,
                confirmationPassword: repeatPassword,
            });

            if (response.status === 200) {
                alert('Zmena hesla bola úspešná');
                navigate(-1); // Go back on successful password change
            }
        } catch (err) {
            if (err instanceof AxiosError) {
                // Now we know it's an AxiosError
                if (err.response && err.response.status === 400) {
                    setError('Zadané aktuálne heslo je nesprávne');
                }
            }
        }
    };

    return (
        <Container>
            <Header>
                <BackButton onClick={() => navigate(-1)}>&larr;</BackButton>
                <Heading>Zmeniť heslo</Heading>
            </Header>
            <Label>Aktuálne heslo</Label>
            <Input
                type="password"
                placeholder="Aktuálne heslo"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <Label>Nové heslo</Label>
            <Input
                type="password"
                placeholder="Nové heslo"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
            />
            <Label>Zopakovať nové heslo</Label>
            <Input
                type="password"
                placeholder="Zopakovať nové heslo"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <SaveButton onClick={handleSave}>Uložiť</SaveButton>
        </Container>
    );
};

export default ChangePassword;
