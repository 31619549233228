import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { useDispatch } from "react-redux";
import { loginSuccess } from "../reducers/authSlice";
import api from "../api/axiosInstance";

const imageSrc = `${process.env.PUBLIC_URL}/g3.png`;

interface RegisterModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onRegister: () => void;
}
// Modal background and gradient styles to match the mobile look
const GradientBackground = styled.div`
    background: linear-gradient(135deg, #f56607, #000);
    padding-top: 15px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5); /* Add shadow for depth */
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

const Label = styled.label`
    margin-bottom: 5px;
    font-weight: bold;
    color: white;  // Make the label text white
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);  /* Add shadow to text */

`;

const Input = styled.input`
    margin: 10px 0;
    padding: 15px;
    width: 100%;
    border-radius: 10px;
    border: none;
    background-color: rgba(255, 255, 255, 0.2);  /* Semi-transparent input background */
    color: white;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);  /* Add shadow to text */
    font-size: 16px;

    &::placeholder {
        color: #ddd;  /* Lighter placeholder text */
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 10px rgba(245, 102, 7, 0.8);  /* Highlighted input on focus */
    }
`;

// Ensure Button styles match the login modal
const Button = styled.button<{ disabled?: boolean }>`
    margin: 10px 0;
    padding: 15px;
    background-color: ${props => (props.disabled ? '#ccc' : 'rgba(255, 255, 255, 0.1)')};
    color: white;
    border: none;
    border-radius: 10px;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 18px;
    font-weight: bold;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 5px rgba(245, 102, 7, 0.5);  /* Add shadow for depth */

    &:hover {
        background-color: ${props => (props.disabled ? '#ccc' : 'rgba(255, 255, 255, 0.2)')};
    }
`;

const Header = styled.h2`
    text-align: center;
    color: white;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);

`;

const ErrorMessage = styled.p`
    color: red;
    text-align: center;
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
`;

const Checkbox = styled.input`
    margin-right: 10px;
`;

const TermsLink = styled.a`
    color: #f56607;
    text-decoration: underline;
    &:hover {
        color: #ff7f00;
    }
`;

const customStyles = {
    content: {
        width: '100%',          // Takes full width of its container
        maxWidth: '500px',       // Set a max-width for the modal
        height: 'auto',          // Adjust height based on content
        margin: 'auto',          // Center the modal in the container
        padding: '0',
        borderRadius: '10px',    // Keep the rounded corners as per the design
        border: 'none',
        background: 'none',      // Transparent background for content
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',  // Semi-transparent overlay
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0',
        height: '100vh',         // Ensures it stretches across the screen
    }
};



const RegisterModal: React.FC<RegisterModalProps> = ({ isOpen, onRequestClose, onRegister }) => {
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [role] = useState('USER'); // default role
    const [error, setError] = useState<string | null>(null);
    const [isTermsChecked, setIsTermsChecked] = useState(false);

    const dispatch = useDispatch();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (password !== password2) {
            setError("Heslá sa nezhodujú");
            return;
        }

        if (!isTermsChecked) {
            setError("Musíte súhlasiť s podmienkami používania.");
            return;
        }

        try {
            const response = await api.post('/v1/auth/register', {
                email,
                password,
                role,
                fullName
            });
            const { accessToken, refreshToken, user } = response.data;
            dispatch(loginSuccess({ accessToken, refreshToken, user }));
            onRequestClose();
            onRegister();
        } catch (error: any) {
            if (error.response && error.response.status === 409) {
                setError("Používateľ s týmto emailom už existuje.");
            } else {
                setError("Registrácia zlyhala. Skúste to znova.");
            }
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="custom-modal-content"
            style={customStyles}
            ariaHideApp={false}
        >
            <GradientBackground>
                <Header>Registrácia</Header>
                    <Form onSubmit={handleSubmit}>
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                        <Label>Email</Label>
                        <Input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Label>Celé meno</Label>
                        <Input
                            type="text"
                            placeholder="Celé meno"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        <Label>Heslo</Label>
                        <Input
                            type="password"
                            placeholder="Heslo"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Label>Zopakuj heslo</Label>
                        <Input
                            type="password"
                            placeholder="Zopakuj heslo"
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}
                        />
                        <CheckboxContainer>
                            <Checkbox
                                type="checkbox"
                                checked={isTermsChecked}
                                onChange={(e) => setIsTermsChecked(e.target.checked)}
                            />
                            <Label>
                                Súhlasím s{" "}
                                <TermsLink href="/sample.pdf" target="_blank" rel="noopener noreferrer">
                                    Obchodnými podmienkami a podmienkami používania.
                                </TermsLink>
                            </Label>
                        </CheckboxContainer>
                        <Button type="submit" disabled={!isTermsChecked}>
                            Registrovať
                        </Button>
                    </Form>
            </GradientBackground>
        </Modal>
    );
};

export default RegisterModal;
