import React, { useState, ChangeEvent } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaUpload, FaPlus, FaArrowLeft, FaSpinner, FaCheckCircle } from 'react-icons/fa';
import api from "../api/axiosInstance";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const PlusButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: #f56607;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 2em;
    cursor: pointer;

    &:hover {
        background-color: #f58802;
    }
`;

const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    max-width: 400px;
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
`;

const BackButton = styled.button`
    align-self: flex-start;
    background-color: transparent;
    border: none;
    color: #f56607;
    cursor: pointer;
    font-size: 1.5em;

    &:hover {
        color: #f58802;
    }
`;

const Input = styled.input`
    margin-top: 20px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ddd;
`;

const TextArea = styled.textarea`
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    height: 150px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ddd;
`;

const FileInputLabel = styled.label`
    display: flex;
    align-items: center;
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #f56607;
    color: white;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #f58802;
    }
`;

const FileInput = styled.input`
    display: none;
`;

const FileName = styled.div`
    margin-top: 10px;
    font-size: 1em;
    color: #555;
`;

const SubmitButton = styled.button`
    margin: 10px 0;
    padding: 10px 20px;
    background-color: #f56607;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #f58802;
    }
`;

const spinnerAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const SpinnerIcon = styled(FaSpinner)`
    animation: ${spinnerAnimation} 2s linear infinite;
`;

const SuccessIcon = styled(FaCheckCircle)`
    color: green;
    font-size: 2em;
    margin-top: 20px;
`;

const LongResponseMessage = styled.div`
    color: red;
    margin-top: 10px;
    font-size: 1em;
    text-align: center;
`;

const UploadSection: React.FC = () => {
    const [showUpload, setShowUpload] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState('');
    const [heading, setHeading] = useState('');
    const [description, setDescription] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [longResponse, setLongResponse] = useState(false); // New state for long response

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);
        }
    };

    const handleSubmit = async () => {
        setIsUploading(true);
        setUploadSuccess(false);
        setLongResponse(false); // Reset long response state

        const postDto = {
            heading,
            description,
        };

        const formData = new FormData();
        formData.append('post', new Blob([JSON.stringify(postDto)], { type: 'application/json' }));
        formData.append('file', file as Blob);

        // Set a timeout for long response handling
        const responseTimeout = setTimeout(() => {
            setLongResponse(true); // Show the long response message after 30 seconds
            setIsUploading(false); // Stop the loading indicator
        }, 30000); // 30 seconds

        try {
            const response = await api.post('/posts', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                timeout: 1200000, // 20 minutes in milliseconds
            });
            setUploadSuccess(true);
            clearTimeout(responseTimeout); // Clear the timeout if the response is quick
            setTimeout(() => {
                window.location.reload(); // Refresh the page after success
            }, 1000); // Adjust the delay as needed
        } catch (error) {
            console.error('Chyba pri nahrávaní:', error);
            setIsUploading(false);
            clearTimeout(responseTimeout); // Clear the timeout on error
        }
    };

    return (
        <Container>
            {!showUpload ? (
                <PlusButton onClick={() => setShowUpload(true)}>
                    <FaPlus />
                </PlusButton>
            ) : (
                <UploadContainer>
                    <BackButton onClick={() => setShowUpload(false)}>
                        <FaArrowLeft />
                    </BackButton>
                    <h2>Pridať príspevok</h2>
                    <Input
                        type="text"
                        placeholder="Zadajte názov"
                        value={heading}
                        onChange={(e) => setHeading(e.target.value)}
                    />
                    <TextArea
                        placeholder="Zadajte popis"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    {fileName && <FileName>Súbor: {fileName}</FileName>}
                    <FileInputLabel>
                        <FaUpload style={{ marginRight: '10px' }} />
                        Nahrať súbor
                        <FileInput type="file" onChange={handleFileChange} />
                    </FileInputLabel>
                    {isUploading ? (
                        <div>
                            <SpinnerIcon /> Súbor sa nahráva...
                        </div>
                    ) : uploadSuccess ? (
                        <SuccessIcon />
                    ) : (
                        <SubmitButton onClick={handleSubmit}>Pridať</SubmitButton>
                    )}
                    {longResponse && (
                        <LongResponseMessage>
                            Nahrávanie trvá príliš dlho, budete informovaní emailom po dokončení.
                        </LongResponseMessage>
                    )}
                </UploadContainer>
            )}
        </Container>
    );
};

export default UploadSection;
