import React, {useState} from 'react';
import styled from 'styled-components';
import ModalComponent from './ModalComponent';
import api from "../api/axiosInstance";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/store";
import {logout} from "../reducers/authSlice";

interface DeleteAccountModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onDeleteAccount: () => void;
}

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const ConfirmButton = styled.button`
    background: #ff4d4d;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background: #ff0000;
    }
`;

const CancelButton = styled.button`
    background: #ddd;
    color: #333;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background: #ccc;
    }
`;

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ isOpen, onRequestClose, onDeleteAccount }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch(); // Get the dispatch function

    const handleDelete = async () => {
        setIsDeleting(true);
        try {
            await api.delete(`/account/${user?.id}`);

            window.location.reload();

            // After successful deletion, log the user out
            dispatch(logout());

            // Refresh the website
        } catch (err) {
            setIsDeleting(false);
            // Handle error if needed
        }
    };

    return (
        <ModalComponent isOpen={isOpen} onClose={onRequestClose} title="Odstraniť účet">
            <p>Ste si istí, že chcete odstrániť svoj účet? Tento krok je nezvratný.</p>
            <ButtonContainer>
                <ConfirmButton onClick={handleDelete}>Áno, odstrániť účet</ConfirmButton>
                <CancelButton onClick={onRequestClose}>Nie, ponechať účet</CancelButton>
            </ButtonContainer>
        </ModalComponent>
    );
};

export default DeleteAccountModal;
