// src/config.ts

type Config = {
    API_URL: string;
};

const development: Config = {
    API_URL: 'https://api.goly-esa.sk/api',
};

const production: Config = {
    API_URL: 'https://api.goly-esa.sk/api',
};

const configMap = {
    development,
    production,
};

// Use type assertion to ensure `process.env.NODE_ENV` is a valid environment
const env = process.env.NODE_ENV as 'development' | 'production';

const config: Config = configMap[env] || development;

export default config;
