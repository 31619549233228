import React, { useEffect, useRef, useState } from 'react';
import Plyr from 'plyr';
import Hls from 'hls.js';
import api from "../api/axiosInstance";
import '../styles.css';

interface ChatBubbleAudioPlayerProps {
    audioId: number;
    autoplay?: boolean;
}

const ChatBubbleAudioPlayer: React.FC<ChatBubbleAudioPlayerProps> = ({ audioId, autoplay = false }) => {
    const [audioSrc, setAudioSrc] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const audioElementRef = useRef<HTMLAudioElement | null>(null);
    const playerRef = useRef<Plyr | null>(null);

    useEffect(() => {
        const fetchAndModifyPlaylist = async () => {
            try {
                const baseURL = api.defaults.baseURL;

                // Fetch the HLS playlist (.m3u8) from the backend
                const response = await api.get(`/files/${audioId}/playlist.m3u8`, {
                    responseType: 'text',
                });

                let playlist = response.data;

                if (baseURL) {
                    // Modify the playlist to replace segment URLs with full paths
                    playlist = playlist.replace(/output(\d+).ts/g, (match: any, p1: any) => {
                        return `${baseURL}/files/${audioId}/output${p1}.ts`;
                    });
                }

                // Create a blob URL for the modified playlist
                const blob = new Blob([playlist], { type: 'application/vnd.apple.mpegurl' });
                const url = URL.createObjectURL(blob);
                setAudioSrc(url);
            } catch (error) {
                console.error('Error fetching audio:', error);
                setError('Could not load audio');
            }
        };

        fetchAndModifyPlaylist();

        return () => {
            if (audioSrc) {
                URL.revokeObjectURL(audioSrc);
            }
            if (playerRef.current) {
                playerRef.current.destroy();
            }
        };
    }, [audioId]);

    useEffect(() => {
        if (audioSrc && audioElementRef.current) {
            // Initialize Plyr
            playerRef.current = new Plyr(audioElementRef.current, {
                autoplay: autoplay,
                controls: ['play', 'mute', 'settings'],
                muted: autoplay, // Mute the audio if autoplay is enabled to avoid browser restrictions
            });

            // HLS.js support
            if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(audioSrc);
                hls.attachMedia(audioElementRef.current);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    if (autoplay) {
                        audioElementRef.current?.play().catch((err) => {
                            console.error('Autoplay failed:', err);
                        });
                    }
                });
            } else if (audioElementRef.current.canPlayType('application/vnd.apple.mpegurl')) {
                // For browsers that support HLS natively
                audioElementRef.current.src = audioSrc;
                if (autoplay) {
                    audioElementRef.current.play().catch((err) => {
                        console.error('Autoplay failed:', err);
                    });
                }
            }
        }
    }, [audioSrc, autoplay]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <audio ref={audioElementRef} className="plyr" controls />
        </div>
    );
};

export default ChatBubbleAudioPlayer;
