import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from "../store/store";
import api from "../api/axiosInstance";
import OnlyPremium from './OnlyPremium'; // Import the OnlyPremium component

interface NotificationModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onSettingsUpdate: (data: any) => void;
}

interface UserNotificationSettings {
    userId: number;
    notifEmail: string;
    phone: string;
    smsNotifEnabled: boolean;
    emailNotifEnabled: boolean;
}

const FormContainer = styled.div`
    width: 100%;
    margin: 0 auto;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

const Input = styled.input`
    margin: 5px 0 15px 0;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ddd;
`;

const Check = styled.input.attrs({ type: 'checkbox' })`
    margin-right: 10px;
    accent-color: #f56607; /* This will make the checkbox orange */
`;

const Button = styled.button`
    margin: 20px 0 10px 0;
    padding: 10px 20px;
    background-color: #f56607;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #e1860f;
    }
`;

const Header = styled.h2`
    text-align: center;
`;

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

const Label = styled.label`
    margin-left: 10px;
    font-weight: bold;
    flex: 1;
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const ErrorMessage = styled.span`
    color: red;
    margin-left: 0px;
    margin-bottom: 10px;
    font-size: 0.9em;
`;

const NotificationModal: React.FC<NotificationModalProps> = ({ isOpen, onRequestClose, onSettingsUpdate }) => {
    const user = useSelector((state: RootState) => state.auth.user);  // Fetch user info
    const [settings, setSettings] = useState<UserNotificationSettings | null>(null);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<{ phoneError: string, emailError: string, submitError: string }>({
        phoneError: '',
        emailError: '',
        submitError: ''
    });
    const [notificationsDisabled, setNotificationsDisabled] = useState(false);

    const phoneRegex = /^\+?[1-9]\d{1,14}$/;

    useEffect(() => {
        if (isOpen && user?.isPremium) {
            const loadSettings = async () => {
                try {
                    const response = await api.get(`/account/notifications/${user.id}`);
                    setSettings(response.data);
                    setNotificationsDisabled(!response.data.smsNotifEnabled && !response.data.emailNotifEnabled);
                } catch (err) {
                    setErrors((prev) => ({ ...prev, submitError: 'Failed to load settings' }));
                } finally {
                    setLoading(false);
                }
            };
            loadSettings();
        }
    }, [isOpen, user?.id, user?.isPremium]);

    const handlePhoneChange = (value: string) => {
        setSettings((prevSettings) => prevSettings ? { ...prevSettings, phone: value } : null);
        if (!phoneRegex.test(value)) {
            setErrors((prev) => ({ ...prev, phoneError: 'Invalid phone number' }));
        } else {
            setErrors((prev) => ({ ...prev, phoneError: '' }));
        }
    };

    const handleEmailChange = (value: string) => {
        setSettings((prevSettings) => prevSettings ? { ...prevSettings, notifEmail: value } : null);
        if (!value.includes('@')) {
            setErrors((prev) => ({ ...prev, emailError: 'Invalid email address' }));
        } else {
            setErrors((prev) => ({ ...prev, emailError: '' }));
        }
    };

    const handleCheckboxChange = (key: keyof UserNotificationSettings) => {
        setSettings((prevSettings) => prevSettings ? { ...prevSettings, [key]: !prevSettings[key] } : null);
    };

    const handleDisableNotificationsChange = () => {
        setNotificationsDisabled(!notificationsDisabled);
        if (!notificationsDisabled) {
            setSettings((prevSettings) => prevSettings ? {
                ...prevSettings,
                smsNotifEnabled: false,
                emailNotifEnabled: false,
                notifEmail: '',
                phone: ''
            } : null);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!settings) return;

        if (settings.emailNotifEnabled && (!settings.notifEmail || errors.emailError)) {
            setErrors((prev) => ({ ...prev, emailError: 'Email cannot be empty and must include "@"' }));
            return;
        }

        if (!settings.emailNotifEnabled && !settings.smsNotifEnabled && !notificationsDisabled) {
            setErrors((prev) => ({ ...prev, submitError: 'Please select at least one option.' }));
            return;
        }

        try {
            const response = await api.put('/account/update-notifications', settings);
            onSettingsUpdate(response.data);
            onRequestClose();
        } catch (err) {
            const response = (err as any).response;
            if (response && response.status === 403) {
                alert('Only premium users can update these settings.');
            } else {
                setErrors((prev) => ({ ...prev, submitError: 'Failed to update notification settings.' }));
            }
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="custom-modal-content"
            style={customStyles}
            ariaHideApp={false}
        >
            {user?.isPremium ? (
                <FormContainer>
                    <Header>Nastavenia upozornení</Header>
                    <Form onSubmit={handleSubmit}>
                        <CheckboxContainer>
                            <Check
                                type="checkbox"
                                checked={settings?.emailNotifEnabled || false}
                                onChange={() => handleCheckboxChange('emailNotifEnabled')}
                                disabled={notificationsDisabled}
                            />
                            <Label>Emailové upozornenia</Label>
                        </CheckboxContainer>
                        <Input
                            type="email"
                            placeholder="Zadajte email"
                            value={settings?.notifEmail || ''}
                            onChange={(e) => handleEmailChange(e.target.value)}
                            disabled={!settings?.emailNotifEnabled || notificationsDisabled}
                        />
                        {errors.emailError && <ErrorMessage>{errors.emailError}</ErrorMessage>}

                        <CheckboxContainer>
                            <Check
                                type="checkbox"
                                checked={notificationsDisabled}
                                onChange={handleDisableNotificationsChange}
                            />
                            <Label>Zakázať upozornenia</Label>
                        </CheckboxContainer>

                        {errors.submitError && <ErrorMessage>{errors.submitError}</ErrorMessage>}
                        <Button type="submit">Potvrdiť</Button>
                    </Form>
                </FormContainer>
            ) : (
                <OnlyPremium />
            )}
        </Modal>
    );
};

export default NotificationModal;
