import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
    padding: 20px;
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
`;

const Header = styled.h2`
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
`;

const Message = styled.p`
    font-size: 18px;
    color: #555;
    margin-bottom: 20px;
`;

const Button = styled.button`
    padding: 10px 20px;
    background-color: #f58802;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
        background-color: #f56607;
    }
`;

const OnlyPremium: React.FC = () => {
    const user = useSelector((state: RootState) => state.auth.user);
    const navigate = useNavigate();

    const handleUpgrade = () => {
        navigate('/account'); // Adjust the path to your upgrade page
    };

    return (
        <Container>
            <Header>Táto funkcia je len pre prémiových používateľov</Header>
            <Message>
                Ahoj, <strong>{user?.fullName}</strong>!
                Táto sekcia je dostupná len pre prémiových používateľov. Pre získanie prístupu k tejto a ďalším exkluzívnym funkciám si prosím aktualizuj svoj účet na prémiovú verziu.
            </Message>
            <Button onClick={handleUpgrade}>Aktualizovať na Prémiový účet</Button>
        </Container>
    );
};

export default OnlyPremium;
