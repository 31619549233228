import { Client, Message, StompConfig } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

class WebSocketService {
    private client: Client;
    private subscriptions: { topic: string, callback: (message: Message) => void }[] = [];

    constructor() {
        const stompConfig: StompConfig = {
            connectHeaders: {},
            webSocketFactory: () => new SockJS('https://api.goly-esa.sk/chat-websocket'),
            debug: (str) => {
            },
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
        };

        this.client = new Client(stompConfig);

        // Handle subscriptions when connected
        this.client.onConnect = () => {
            this.subscriptions.forEach(({ topic, callback }) => {
                this.client.subscribe(topic, callback);
            });
        };
    }

    public activate() {
        this.client.activate();
    }

    public deactivate() {
        this.client.deactivate();
    }

    public subscribeToTopic(topic: string, callback: (message: Message) => void) {
        // If already connected, subscribe immediately
        if (this.client.connected) {
            this.client.subscribe(topic, callback);
        } else {
            // Otherwise, queue the subscription to be handled after connection is established
            this.subscriptions.push({ topic, callback });
        }
    }

    public sendMessage(destination: string, body: any) {
        this.client.publish({
            destination: destination,
            body: JSON.stringify(body),
        });
    }
}

export const webSocketService = new WebSocketService();
