import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FaChevronDown, FaPlus, FaTrash } from 'react-icons/fa';
import NewsUploadSection from './NewsUploadSection';
import api from "../api/axiosInstance";
import { RootState } from "../store/store";
import OnlyPremium from './OnlyPremium'; // Import OnlyPremium component

interface NewsItem {
    id: number; // Ensure each news item has a unique id
    time: string;
    title: string;
    content: string;
}

const Wrapper = styled.div`
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 25px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
`;

const NewsHeader = styled.h2`
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
`;

const NewsItemContainer = styled.div`
    border-bottom: 1px solid #ccc;
    padding: 10px;
    position: relative;
    cursor: pointer;
    &:hover .delete-icon {
        opacity: 1;
    }
`;

const NewsTime = styled.span`
    font-weight: bold;
    margin-right: 10px;
`;

const NewsTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
`;

const NewsContent = styled.div`
    margin-top: 10px;
    font-size: 14px;
    color: #555;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
`;

const PlusButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: #f56607;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 2em;
    cursor: pointer;
    margin: 20px auto;

    &:hover {
        background-color: #f58802;
    }
`;

const NewsUploadSectionContainer = styled.div`
    margin-bottom: 20px;
`;

const DeleteIcon = styled(FaTrash)`
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    color: red;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;

    &:hover {
        color: darkred;
    }
`;

const ChevronIcon = styled(FaChevronDown)`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
`;

const News: React.FC = () => {
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    const [showUpload, setShowUpload] = useState(false);
    const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
    const user = useSelector((state: RootState) => state.auth.user);

    const fetchRecentNews = async () => {
        if (user?.isPremium) {
            try {
                const response = await api.get('/news/recent');
                setNewsItems(response.data);
            } catch (error) {
                console.error('Error fetching recent news:', error);
            }
        }
    };

    useEffect(() => {
        if (user?.isPremium) {
            fetchRecentNews();
        }
    }, [user?.isPremium]);

    const toggleExpand = (index: number) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const handleDelete = async (id: number) => {
        try {
            await api.delete(`/news/${id}`);
            fetchRecentNews(); // Refresh the list after deletion
        } catch (error) {
            console.error('Error deleting news item:', error);
        }
    };

    return (
        <Wrapper>
            <NewsHeader>Express Správy</NewsHeader>

            {user?.isAdmin && (
                <>
                    {showUpload ? (
                        <NewsUploadSectionContainer>
                            <NewsUploadSection
                                onBack={() => setShowUpload(false)}
                                onReload={fetchRecentNews}
                            />
                        </NewsUploadSectionContainer>
                    ) : (
                        <PlusButton onClick={() => setShowUpload(true)}>
                            <FaPlus />
                        </PlusButton>
                    )}
                </>
            )}

            {newsItems.map((news, index) => (
                <NewsItemContainer key={news.id} onClick={() => toggleExpand(index)}>
                    <NewsTitle>
                        <div>
                            <NewsTime>{news.time}</NewsTime>
                            {news.title}
                        </div>
                        {user?.isAdmin && (
                            <DeleteIcon
                                className="delete-icon"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(news.id);
                                }}
                            />
                        )}
                        <ChevronIcon />
                    </NewsTitle>
                    {expandedIndex === index && (
                        <NewsContent>{news.content}</NewsContent>
                    )}
                </NewsItemContainer>
            ))}
        </Wrapper>
    );
};

export default News;
