import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { Buffer } from 'buffer';
import { FaTrash } from 'react-icons/fa';
import 'react-h5-audio-player/lib/styles.css';
import CommentSection from '../components/CommentSection';
import { RootState } from "../store/store";
import { useNavigate } from 'react-router-dom';
import RegisterModal from '../components/RegisterModal';
import LoginModal from '../components/LoginModal';
import '../App.css';
import api from "../api/axiosInstance";
import { Post } from "../models/types";
import { updateUser } from "../reducers/authSlice";
import LazyImage from "./LazyImage";
import VideoPlayer from './VideoPlayer';  // Import the VideoPlayer component
import AudioPlayer from './AudioPlayer';
import audioPlayer from "./AudioPlayer";  // Import the VideoPlayer component

const PostsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    padding: 0 10px;
    background-color: #f8f9fa;  // Light background
`;

const PostWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 600px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0;
    box-shadow: 0px 5px 15px rgba(245, 102, 7, 0.6);  // Orange shadow with blur
    background-color: #fff;
`;

const Heading = styled.h2`
    font-size: 1.5em;
    margin-bottom: 10px;
    text-align: left;
`;

const Description = styled.p`
    font-size: 0.9em;
    margin-bottom: 20px;
    white-space: pre-wrap;
    text-align: left;
`;

const Media = styled.div`
    margin-top: 10px;
    width: 100%;
    img, video, audio {
        width: 100%;
        height: auto;
        border-radius: 5px;
    }
`;

const Button = styled.button`
    margin: 10px 0;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

const VideoThumbnail = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    margin-top: 10px;
    overflow: hidden;
    border-radius: 5px;
`;

const ThumbnailImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const VideoPlayButton = styled.button`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
`;

const AudioPlayerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
`;

const IconButton = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    color: #ff4d4d;
    border: none;
    cursor: pointer;
    font-size: 1.0em;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        color: #ff0000;
    }
`;

const LockedContent = styled.div`
    font-size: 1em;
    text-align: left;
    color: #fff;
    padding: 20px;
    background-color: #1C1C1E;
    border-radius: 10px;
    border: 1px solid #333;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > p {
        margin-bottom: 20px;
    }

    & > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 20px;

        span {
            font-weight: bold;
            color: #fff;
        }

        button {
            background-color: #FF7F00;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
                background-color: #f56607;
            }
        }
    }
`;


const PremHeading = styled.h3`
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #f56607;
`;

const LoadMoreButton = styled.button`
    padding: 10px;
    background-color: #f56607;
    color: white;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    &:hover {
        background-color: #ff7f00;
    }
`;



const LatestPosts: React.FC = () => {
    const [posts, setPosts] = useState<Post[]>([]);
    const [playingVideo, setPlayingVideo] = useState<number | null>(null);
    const [page, setPage] = useState(0);
    const [hasMorePosts, setHasMorePosts] = useState(true);
    const user = useSelector((state: RootState) => state.auth.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const initialFetchDone = useRef(false);

    const [isChoiceModalOpen, setIsChoiceModalOpen] = useState(false);
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    const openChoiceModal = () => {
        if (user) {
            navigate('/account');
        } else {
            setIsChoiceModalOpen(true);
        }
    };

    const closeChoiceModal = () => setIsChoiceModalOpen(false);

    const handleLogin = () => {
        closeChoiceModal();
        setIsLoginModalOpen(true);
    };

    const handleRegister = () => {
        closeChoiceModal();
        setIsRegisterModalOpen(true);
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userResponse = await api.get(`/account/${user?.id}`);
                if (userResponse && userResponse.data) {
                    dispatch(updateUser(userResponse.data));
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        if (user && !initialFetchDone.current) {
            fetchUser();
        }
    }, [user?.id, dispatch]);

    useEffect(() => {
        const fetchPosts = async () => {
            if (user?.isPremium) {
                try {
                    const response = await api.get(`/posts?page=${page}&size=10`);
                    if (Array.isArray(response.data.content)) {
                        const fetchedPosts: Post[] = response.data.content;

                        const postsWithThumbnails = await Promise.all(
                            fetchedPosts.map(async (post: Post) => {
                                if (post.type.startsWith('video/')) {
                                    const thumbnailResponse = await api.get(`/posts/thumbnail/${post.id}`, { responseType: 'arraybuffer' });
                                    return {
                                        ...post,
                                        thumbnail: thumbnailResponse.data,
                                        contentType: thumbnailResponse.headers['content-type'],
                                    };
                                } else if (post.type.startsWith('image/')) {
                                    const thumbnailResponse = await api.get(`/posts/thumbnail/${post.id}`, { responseType: 'arraybuffer' });
                                    const thumbnailBlob = new Blob([thumbnailResponse.data], { type: thumbnailResponse.headers['content-type'] });
                                    const thumbnailUrl = URL.createObjectURL(thumbnailBlob);
                                    return {
                                        ...post,
                                        thumbnailUrl,
                                    };
                                } else {
                                    return post;
                                }
                            })
                        );

                        setPosts((prevPosts) => [...prevPosts, ...postsWithThumbnails]);

                        // Set hasMorePosts based on the fetched post count
                        setHasMorePosts(fetchedPosts.length === 10);
                    } else {
                        console.error('Unexpected API response structure:', response.data);
                        setHasMorePosts(false);
                    }
                } catch (error) {
                    console.error('Error fetching posts:', error);
                    setHasMorePosts(false);
                }
            } else {
                const lockedPosts = Array.from({ length: 10 }, (_, index) => ({
                    id: index + 1 + page * 10,
                    heading: `Locked Post ${index + 1 + page * 10}`,
                    description: 'This content is locked. Please upgrade to premium to view this content.',
                    type: 'locked',
                })) as Post[];

                setPosts((prevPosts) => [...prevPosts, ...lockedPosts]);
                setHasMorePosts(false);
            }
        };

        if (!initialFetchDone.current || page > 0) {
            fetchPosts();
            initialFetchDone.current = true;
        }
    }, [user?.isPremium, page]);

    const handleDelete = async (postId: number) => {
        try {
            await api.delete(`/posts/${postId}`);
            setPosts(posts.filter(post => post.id !== postId));
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    };

    const loadMorePosts = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handleVideoClick = (postId: number) => {
        setPlayingVideo(postId);
    };

    return (
        <PostsContainer>
            {posts.map(post => (
                <PostWrapper key={post.id}>
                    {user?.isPremium && post.type !== 'locked' ? (
                        <>
                            <Heading>{post.heading}</Heading>
                            <Description>{post.description}</Description>
                            <Media>
                                {post.type.startsWith('video/') ? (
                                    playingVideo === post.id ? (
                                        <VideoPlayer
                                            videoId={post.id}
                                            autoplay={true}
                                            onReady={(player) => {
                                            }}
                                        />
                                    ) : (
                                        <VideoThumbnail>
                                            {post.thumbnail && (
                                                <ThumbnailImage
                                                    src={`data:${post.contentType};base64,${Buffer.from(post.thumbnail).toString('base64')}`}
                                                    alt="Video thumbnail"
                                                />
                                            )}
                                            <VideoPlayButton onClick={() => handleVideoClick(post.id)}>Play Video</VideoPlayButton>
                                        </VideoThumbnail>
                                    )
                                ) : post.type.startsWith('audio/') ? (
                                    <AudioPlayerWrapper>
                                        <AudioPlayer
                                            audioId={post.id}
                                            autoplay={false}
                                        />
                                    </AudioPlayerWrapper>
                                ) : post.type.startsWith('image/') ? (
                                    <LazyImage
                                        postId={post.id}
                                        thumbnailSrc={post.thumbnailUrl}
                                        alt="Post media"
                                    />
                                ) : (
                                    <p></p>
                                )}
                            </Media>
                            <CommentSection postId={post.id} commentsCount={post.commentsCount ?? 0} />
                            {user?.isAdmin && (
                                <IconButton onClick={() => handleDelete(post.id)}>
                                    <FaTrash />
                                </IconButton>
                            )}
                        </>
                    ) : (
                        <LockedContent>
                            <PremHeading>Prístup do prémiového obsahu</PremHeading>
                            <p>Prihlás sa na odber a získej doplnkový obsah k našim epizódam podcastov.</p>
                            <div>
                                <span>9,00 €/mesiac</span>  {/* Bolded price */}
                                <Button onClick={openChoiceModal}>Predplatiť</Button>  {/* Orange button */}
                            </div>
                        </LockedContent>

                    )}
                </PostWrapper>
            ))}
            {hasMorePosts && (
                <LoadMoreButton onClick={loadMorePosts}>
                    Načítať viac
                </LoadMoreButton>
            )}
            <Modal
                isOpen={isChoiceModalOpen}
                onRequestClose={closeChoiceModal}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',  // Ensure the overlay takes up the full viewport height
                    },
                    content: {
                        position: 'relative',
                        inset: 'auto',
                        padding: '0', // No padding to let the gradient container control padding
                        borderRadius: '10px',
                        background: 'none', // Transparent background, will use gradient container
                        maxWidth: '400px',
                        width: '90%',
                        height: 'auto', // Ensure content takes up space based on its content
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }
                }}
                ariaHideApp={false}
            >
                <div
                    style={{
                        background: 'linear-gradient(135deg, #f56607, #000)', // Gradient background
                        padding: '30px',
                        borderRadius: '10px',
                        textAlign: 'center',
                    }}
                >
                    <Button
                        style={{
                            marginBottom: '20px',
                            padding: '15px 30px',
                            borderRadius: '10px',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            color: 'white',
                            fontWeight: 'bold',
                            boxShadow: '0 3px 5px rgba(245, 102, 7, 0.5)',
                            cursor: 'pointer',
                            border: 'none',
                            textShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)'
                        }}
                        onClick={handleLogin}
                    >
                        Prihlásiť sa
                    </Button>
                    <Button
                        style={{
                            padding: '15px 30px',
                            borderRadius: '10px',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            color: 'white',
                            fontWeight: 'bold',
                            boxShadow: '0 3px 5px rgba(245, 102, 7, 0.5)',
                            cursor: 'pointer',
                            border: 'none',
                            textShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)',
                            margin: '10px'
                        }}
                        onClick={handleRegister}
                    >
                        Registrovať
                    </Button>
                </div>
            </Modal>

            <RegisterModal isOpen={isRegisterModalOpen} onRequestClose={() => setIsRegisterModalOpen(false)} onRegister={() => {
                setIsRegisterModalOpen(false);
                navigate('/account');
            }} />
            <LoginModal isOpen={isLoginModalOpen} onRequestClose={() => setIsLoginModalOpen(false)} onLogin={() => {
                handleLogin();
                navigate('/account');
            }} />
        </PostsContainer>
    );
};

export default LatestPosts;
