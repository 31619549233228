import React, { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { FaCheckCircle, FaTimesCircle, FaSpinner } from 'react-icons/fa';
import styled from 'styled-components';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import { updateUser } from "../reducers/authSlice";
import api from "../api/axiosInstance";
import NotificationModal from './NotificationModal';

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
`;

const LoadingIcon = styled(FaSpinner)`
    color: #007bff;
    font-size: 4rem;
    margin-bottom: 20px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

const SuccessIcon = styled(FaCheckCircle)`
    color: green;
    font-size: 4rem;
    margin-bottom: 20px;
`;

const ErrorIcon = styled(FaTimesCircle)`
    color: red;
    font-size: 4rem;
    margin-bottom: 20px;
`;

const MessageText = styled.p`
    font-size: 1.5rem;
    margin: 0;
`;

const PaymentStatus: React.FC = () => {
    const stripe = useStripe();
    const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
    const [message, setMessage] = useState<string>('Spracovávam platbu...');
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const [isModalOpen, setModalOpen] = useState<boolean>(false); // Modal visibility state

    useEffect(() => {
        const checkPaymentStatus = async () => {
            if (!stripe) {
                setMessage('Spracuvávame platbu');
                return;
            }

            const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

            if (!clientSecret) {
                setStatus('error');
                setMessage('Chýba platobný údaj.');
                return;
            }

            try {
                const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

                if (!paymentIntent) {
                    setStatus('error');
                    setMessage('Platobný zámer sa nepodarilo načítať.');
                    return;
                }

                switch (paymentIntent.status) {
                    case 'succeeded':
                        const updatedUser = await api.put(`/account/premium/${user?.id}`, {});
                        await api.post(`/orders/create?userId=${user?.id}`, {});
                        dispatch(updateUser(updatedUser.data));
                        setStatus('success');
                        setMessage('Platba prebehla úspešne!');
                        setModalOpen(true); // Show the notification modal
                        break;
                    case 'processing':
                        setStatus('loading');
                        setMessage('Platba sa spracuváva, o jej priebehu vás budeme informovať.');
                        break;
                    case 'requires_payment_method':
                        setStatus('error');
                        setMessage('Platba zlyhala. Prosím skuste to zopakovať.');
                        break;
                    default:
                        setStatus('error');
                        setMessage('Nastala neznáma chyba.');
                        break;
                }
            } catch (error) {
                setStatus('error');
                setMessage('Nastala chyba pri načítaní platobného zámeru.');
                console.error('Error retrieving payment intent:', error);
            }
        };

        checkPaymentStatus();
    }, [stripe, user?.id, dispatch]);

    const handleSettingsUpdate = (data: any) => {
        // Handle any updates when settings are changed in the modal
    };

    const handleCloseModal = () => {
        setModalOpen(false); // Close the modal
    };

    return (
        <>
            <MessageContainer>
                {status === 'loading' && <LoadingIcon />}
                {status === 'success' && <SuccessIcon />}
                {status === 'error' && <ErrorIcon />}
                <MessageText>{message}</MessageText>
            </MessageContainer>

            <NotificationModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                onSettingsUpdate={handleSettingsUpdate}
            />
        </>
    );
};

export default PaymentStatus;
