import React, { useState, useEffect,useRef } from 'react';
import {Box, List, ListItem, TextField, Button, IconButton} from '@mui/material';
import ChatBubble from './ChatBubble';
import ChatHeader from './ChatHeader';
import api from '../api/axiosInstance';
import { webSocketService } from '../services/WebSocketService';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import { useReactMediaRecorder } from 'react-media-recorder';
import OnlyPremium from "./OnlyPremium";

const emojiMap: { [key: number]: string } = {
    1: '👍', // Thumbs up
    2: '❤️', // Heart
    3: '😂', // Laughing
    4: '😮', // Surprised
    5: '😢', // Crying
};

interface ChatMessage {
    id: number;             // Unique identifier for the message
    content: string;        // The content of the message
    reactions: Reaction[];  // Array of reactions associated with this message
    sentDate: string;       // The date and time the message was sent (in ISO format)
    timestamp: string;      // The formatted timestamp for display purposes
    audioId?: number | null;   // Optional audio ID, can be a number or null

}

interface Reaction {
    emoji: string;  // The emoji character mapped from the emoji number
    count: number;  // The count of this particular reaction type
    userId: number; // The ID of the user who reacted
}


const Messages: React.FC = () => {
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [message, setMessage] = useState<string>('');
    const user = useSelector((state: RootState) => state.auth.user);
    const isAdmin = user?.isAdmin;
    const [activeSessionName, setActiveSessionName] = useState<string | null>(null);
    const [chatSessionId, setChatSessionId] = useState<number | null>(null);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const [hasNewMessage, setHasNewMessage] = useState(false);  // Add this flag to track new messages

    const [isRecording, setIsRecording] = useState(false);
    const [shouldSaveRecording, setShouldSaveRecording] = useState(false);
    const {
        startRecording,
        stopRecording,
        mediaBlobUrl,
        clearBlobUrl
    } = useReactMediaRecorder({ audio: true });

    const handleStartRecording = () => {
        setIsRecording(true);
        startRecording();
    };

    const handleStopRecording = () => {
        stopRecording();
        setIsRecording(false);
        setShouldSaveRecording(true);  // Indicate that we should save the recording once it's available
    };

    const handleSaveRecording = async () => {
        if (mediaBlobUrl && chatSessionId) {
            try {
                // Fetch the recorded audio from the mediaBlobUrl
                const response = await fetch(mediaBlobUrl);
                const blob = await response.blob();

                // Prepare the formData to send the audio file to the backend
                const formData = new FormData();
                formData.append("file", new File([blob], "voiceMessage.mp3", { type: "audio/mp3" })); // Attach the MP3 file
                formData.append("chatSessionId", chatSessionId.toString()); // Add the chat session ID

                // Send the audio file to the backend and get the response
                const uploadResponse = await api.post(`/files/uploadVoiceMessage`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // Extract the audioId from the upload response
                const audioId = uploadResponse.data;

                // Now send the message with the audioId
                await api.post('/messages', {
                    content: 'Voice message', // Placeholder content for the message
                    chatSessionId,            // The current chat session ID
                    audioId: audioId,         // The ID of the uploaded audio file
                });


                // Clear the Blob URL after successful upload
                clearBlobUrl();
            } catch (error) {
            } finally {
                // Reset the flag to indicate the recording has been saved
                setShouldSaveRecording(false);
            }
        }
    };


    useEffect(() => {
        // When mediaBlobUrl becomes available and shouldSaveRecording is true, save the recording
        if (mediaBlobUrl && shouldSaveRecording) {
            handleSaveRecording();
        }
    }, [mediaBlobUrl, shouldSaveRecording]);  // Re-run when either mediaBlobUrl or shouldSaveRecording changes


    useEffect(() => {
        const fetchActiveSessionAndMessages = async () => {
            try {
                // Fetch the active chat session
                const sessionResponse = await api.get('/chat-sessions/active');
                const activeSession = sessionResponse.data;

                if (activeSession) {
                    setChatSessionId(activeSession.id);
                    setActiveSessionName(activeSession.name);

                    // Fetch messages for the active session including their reactions
                    const messagesResponse = await api.get(`/messages/session/${activeSession.id}`);
                    const messagesWithReactions = messagesResponse.data.map((msg: any) => {
                        const reactions = msg.reactions.map((reaction: any) => ({
                            emoji: emojiMap[reaction.emoji] || '❓',
                            count: reaction.count,
                            userId: reaction.userId,
                        }));

                        return {
                            ...msg,
                            reactions,
                        };
                    });

                    // Update the state with messages and their reactions
                    setMessages(messagesWithReactions);
                } else {
                    setChatSessionId(null);
                    setActiveSessionName(null);
                }
            } catch (error) {
                console.error('Error fetching active session or messages:', error);
            }
        };

        fetchActiveSessionAndMessages();

        // Initialize WebSocket connection
        webSocketService.activate();

        // Subscribe to session start/stop topic
        webSocketService.subscribeToTopic('/topic/session', (sessionUpdate) => {
            const message = sessionUpdate.body;

            if (message === 'START' || message === 'STOP') {
                // Reload the page or re-fetch the session and messages
                fetchActiveSessionAndMessages();
            }
        });

        // Subscribe to messages topic
        webSocketService.subscribeToTopic('/topic/messages', (message) => {
            const receivedMessage = JSON.parse(message.body);
            if (receivedMessage.type === 'DELETE') {
                setMessages((prevMessages) => prevMessages.filter(msg => msg.id !== receivedMessage.id));
            } else {
                setMessages((prevMessages) => {
                    if (!prevMessages.some(msg => msg.id === receivedMessage.id)) {
                        setHasNewMessage(true);  // Set the flag to true when a new message is added
                        return [...prevMessages, receivedMessage];
                    }
                    return prevMessages;
                });
            }
        });

        // Subscribe to reactions topic
        webSocketService.subscribeToTopic('/topic/reactions', (reactionUpdate) => {
            const receivedUpdate = JSON.parse(reactionUpdate.body);

            setMessages((prevMessages) =>
                prevMessages.map((msg) => {
                    if (msg.id === receivedUpdate.messageId) {
                        const reactions = receivedUpdate.reactions.map((reaction: any) => ({
                            emoji: emojiMap[reaction.emoji] || '❓',
                            count: reaction.count,
                            userId: reaction.userId,
                        }));

                        return { ...msg, reactions };
                    }
                    return msg;
                })
            );
        });

        // Cleanup on component unmount
        return () => {
            webSocketService.deactivate();
        };
    }, []);

    const handleCreateSession = async (name: string) => {
        try {
            const response = await api.post('/chat-sessions', { name, isActive: true });
            setActiveSessionName(response.data.name);
            setChatSessionId(response.data.id);
        } catch (error) {
            console.error('Error creating chat session:', error);
        }
    };

    const handleStopSession = async () => {
        if (chatSessionId) {
            try {
                await api.put(`/chat-sessions/${chatSessionId}`, { isActive: false });
                setActiveSessionName(null);
                setChatSessionId(null);
                setMessages([]); // Clear messages when session stops
            } catch (error) {
                console.error('Error stopping chat session:', error);
            }
        }
    };

    const handleSendMessage = async () => {
        if (message.trim() !== '' && chatSessionId) {
            try {
                const response = await api.post('/messages', {
                    content: message,
                    chatSessionId,
                });

                setMessage(''); // Clear the input field after sending the message
            } catch (error) {
            }
        } else {
        }
    };

    const handleDeleteMessage = async (messageId: number) => {
        try {
            await api.delete(`/messages/${messageId}`);
            // Remove the message from the UI after successful deletion
        } catch (error) {
            console.error("Failed to delete message", error);
        }
    };

    const handleReact = async (index: number, emojiIndex: number) => {
        const message = messages[index];
        try {
            await api.post('/message-reactions', {
                userId: user?.id,
                messageId: message.id,
                emoji: emojiIndex,
            });
        } catch (error) {
            console.error('Error reacting to message:', error);
        }
    };

    useEffect(() => {
        // This effect will handle scrolling to the bottom ONLY when a new message is added
        if (chatContainerRef.current && hasNewMessage) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            setHasNewMessage(false);  // Reset the flag after scrolling
        }
    }, [messages, hasNewMessage]);  // Watch for changes in messages and the flag

    // Conditional Rendering: Check if the user is a premium user
    if (!user?.isPremium) {
        return <OnlyPremium />;
    }

    return (
        <Box sx={{ p: 0, width: '100%', maxWidth: '100%', height: '80vh', display: 'flex', flexDirection: 'column', backgroundColor: '#18191A', color: '#fff' }}>
            <ChatHeader
                isAdmin={isAdmin ?? null}  // Convert undefined to null if isAdmin is undefined
                activeSessionName={activeSessionName}
                onCreateSession={handleCreateSession}
                onStopSession={handleStopSession}
            />
            <Box
                ref={chatContainerRef}  // Attach ref to the container
                sx={{ flex: 1, overflowY: 'auto', p: 0.4 }}
            >
                {chatSessionId ? (
                    <List sx={{ width: '100%' }}>
                        {messages.map((msg, index) => (
                            <ListItem key={index} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                {msg.content || msg.audioId ? (
                                    <ChatBubble
                                        message={msg.content}
                                        reactions={msg.reactions}
                                        onReact={(emoji) => handleReact(index, emoji)}
                                        timestamp={msg.timestamp}
                                        sentDate={msg.sentDate}
                                        audioId={msg.audioId}  // Pass the audioUrl if it's a voice message
                                        onDelete={() => handleDeleteMessage(msg.id)}
                                        isAdmin={user?.isAdmin}
                                    />
                                ) : (
                                    <div>Error: Message content or reactions are missing.</div>
                                )}
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <div>Momentálne nie je aktívny žiaden live chat, zastavte sa neskôr.</div>
                )}
            </Box>

            {isAdmin && (
                <Box sx={{ p: 2, display: 'flex', alignItems: 'center', borderTop: '1px solid #3A3B3C' }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Napíšte správu"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleSendMessage();
                            }
                        }}
                        sx={{
                            backgroundColor: '#3A3B3C',
                            borderRadius: '4px',
                            color: '#E4E6EB',
                            input: { color: '#E4E6EB' },
                            label: {
                                color: '#E4E6EB', // Change the label color from blue to white (or your preferred color)
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#3A3B3C', // Normal border color
                                },
                                '&:hover fieldset': {
                                    borderColor: '#3A3B3C', // Hover border color
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#3A3B3C', // Focused border color
                                },
                            },
                            // Customizing the placeholder text color
                            '& .MuiInputBase-input::placeholder': {
                                color: '#E4E6EB', // Change placeholder text color to white
                            },
                        }}
                        InputLabelProps={{
                            style: { color: '#E4E6EB' }, // Change label color (above the input when focused)
                        }}
                        disabled={!chatSessionId}  // Disable input if no active session
                    />
                    <Button
                        variant="contained"
                        onClick={handleSendMessage}
                        sx={{
                            ml: 2,
                            backgroundColor: '#f56607', // Change the button background color to orange
                            '&:hover': {
                                backgroundColor: '#c94b05', // Darker orange on hover
                            },
                        }}
                        disabled={!chatSessionId}  // Disable button if no active session
                    >
                        Odoslať
                    </Button>
                    <IconButton
                        disabled={!chatSessionId}
                        onClick={isRecording ? handleStopRecording : handleStartRecording}
                        sx={{ ml: 2 }}
                    >
                        {isRecording ? (
                            <StopIcon sx={{ color: 'red' }} />
                        ) : (
                            <MicIcon sx={{ color: '#fff' }} />
                        )}
                    </IconButton>
                </Box>
            )}

        </Box>
    );
};

export default Messages;
