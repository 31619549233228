import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import UploadSection from '../components/UploadSection';
import LatestPosts from '../components/LatestPosts';
import ProfileHeader from '../components/ProfileHeader';
import { RootState } from "../store/store";
import NewsUploadSection from "../components/NewsUploadSection";
import api from "../api/axiosInstance";
import News from "../components/News"; // Adjust the import path as necessary

const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
`;

const Divider = styled.div`
    width: 80%;
    height: 1px;
    background-color: #ccc; /* Light divider color */
    margin: 20px 0;
`;

interface NewsItem {
    id: number; // Ensure each news item has a unique id
    time: string;
    title: string;
    content: string;
}

const Home = () => {
    const user = useSelector((state: RootState) => state.auth.user);

    return (
        <HomeContainer>

            <ProfileHeader />
            <Divider />
            <News />
            <Divider />
            {user?.isAdmin && (
                <>
                    <UploadSection />
                </>
            )}
            <LatestPosts />
        </HomeContainer>
    );
};

export default Home;
