import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaCheckCircle, FaShoppingCart } from 'react-icons/fa';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import api from '../api/axiosInstance';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const Modal = styled.div`
    background: white;
    width: 700px; /* Updated to be wider on PC */
    max-width: 100%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #1C1C1E; /* Background color set to match the black background */
`;

const ModalTitle = styled.h2`
    font-size: 1.5em;
    margin: 0;
    display: flex;
    align-items: center;
    color: #f56607; /* Changed to white */
`;

const PriceText = styled.div`
    font-size: 0.9em;
    color: white; /* Changed to white */
`;

const StepNav = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const StepItem = styled.div<{ active: boolean }>`
    display: flex;
    align-items: center;
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
    color: ${(props) => (props.active ? '#FF7F00' : '#ccc')};
    position: relative;
`;

const CheckIcon = styled(FaCheckCircle)`
    color: #FF7F00;
    margin-right: 8px;
`;

const ModalContent = styled.div`
    margin-bottom: 20px;
    color: white; /* Text color in modal content */
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Button = styled.button`
    background-color: #FF7F00; /* Orange button */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #e1860f;
    }
`;

const ModalStep = styled.div`
    border-top: 1px solid #ddd;
    padding: 10px 0;
`;

const StepTitle = styled.h3`
    font-size: 1.2em;
    margin: 10px 0;
    color: #f56607; /* Changed to white */
`;

const ProductImage = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background-color: #e0e0e0;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
`;

const Input = styled.input`
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #f56607;
    background-color: #333; /* Input background color */
    color: white; /* Input text color changed to white */
`;

const ErrorMessage = styled.label`
    margin-top: 20px;
    font-weight: bold;
    display: block;
    text-align: center;
    color: red;
`;

interface ModalComponentProps {
    isOpen: boolean;
    onClose: () => void;
    stripePromise: Promise<Stripe | null>;
    paymentOptions: StripeElementsOptions;
}

const CenteredContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px;  /* Adjust spacing if needed */
`;

const CenteredText = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: #f56607;
    margin: 10px 0; /* Add space between text and image */
`;

const CenteredPrice = styled.p`
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 5px; /* Adjust spacing below price */
`;

const BuyPremium: React.FC<ModalComponentProps> = ({ isOpen, onClose, stripePromise, paymentOptions }) => {
    const user = useSelector((state: RootState) => state.auth.user);
    const theFullName = user ? user.fullName : '';
    const userId = user ? user.id : null;
    const [pricePerItem, setPricePerItem] = useState(9);  // Set price for the item
    const [productName] = useState('Premium Balíček');   // Set product name
    const [productIcon] = useState('/g.png');   // Set product icon from public folder
    const [quantity] = useState(1);  // Fixed quantity to 1
    const totalPrice = pricePerItem * quantity;

    const [fullName, setFullName] = useState(theFullName);
    const [currentStep, setCurrentStep] = useState(0);
    const [streetAddress, setStreetAddress] = useState('');
    const [postcode, setPostcode] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');

    const [billingDetails, setBillingDetails] = useState({
        fullName: '',
        streetAddress: '',
        postcode: '',
        city: '',
        country: '',
    });

    const [showMissingFieldsMessage, setShowMissingFieldsMessage] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const [missingFields, setMissingFields] = useState({
        fullName: false,
        streetAddress: false,
        postcode: false,
        city: false,
        country: false,
    });

    useEffect(() => {
        if (userId) {
            const fetchBillingDetails = async () => {
                try {
                    const response = await api.get(`/account/${userId}`);
                    const { fullName, streetAddress, zipcode, city, country } = response.data;
                    setFullName(fullName || '');
                    setStreetAddress(streetAddress || '');
                    setPostcode(zipcode || '');
                    setCity(city || '');
                    setCountry(country || '');
                } catch (error) {
                    console.error('Error fetching billing details:', error);
                }
            };
            fetchBillingDetails();
        }
    }, [userId]);

    const handleNextStep = async () => {
        if (validateStep()) {
            setCurrentStep(currentStep + 1);
        }
        if (validateStep()) {
            if (currentStep === 1) {
                try {
                    const response = await api.put(`/account/${userId}`, {
                        fullname: fullName,
                        streetAddress: streetAddress,
                        zipcode: postcode,
                        city: city,
                        country: country,
                    });
                } catch (error) {}
            }
        }
    };

    const handlePreviousStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const validateStep = () => {
        if (currentStep === 1) {
            const newMissingFields = {
                fullName: fullName.trim() === '',
                streetAddress: streetAddress.trim() === '',
                postcode: postcode.trim() === '',
                city: city.trim() === '',
                country: country.trim() === '',
            };

            setMissingFields(newMissingFields);
            const hasMissingFields = Object.values(newMissingFields).includes(true);
            setShowMissingFieldsMessage(hasMissingFields);
            return !hasMissingFields;
        }
        return true;
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault(); // Prevent the default button action
        if (!stripe || !elements) return;

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: 'http://localhost:3000/payment-status',
            },
        });

        if (error) {
            console.error('Payment failed:', error.message);
        }
    };

    if (!isOpen) return null;

    return (
        <Overlay onClick={onClose}>
            <Modal onClick={(e) => e.stopPropagation()}>
                <ModalTitle>
                    <FaShoppingCart />
                    Košík
                </ModalTitle>
                <PriceText>{totalPrice} EUR</PriceText>
                <StepNav>
                    {['Baliček', 'Fakturačné Údaje', 'Rekapitulácia', 'Platba'].map((step, index) => (
                        <StepItem key={index} active={currentStep === index}>
                            {currentStep >= index ? <CheckIcon /> : null}
                            {step}
                        </StepItem>
                    ))}
                </StepNav>
                <ModalContent>
                    {currentStep === 0 && (
                        <ModalStep>
                            {/* Centered Content */}
                            <CenteredContent>
                                <ProductImage src="/g.png" alt="Góly a Esá" />
                                <CenteredText>Premium Balíček</CenteredText>
                                <CenteredPrice>{totalPrice} EUR</CenteredPrice>
                            </CenteredContent>
                            <Divider />
                        </ModalStep>
                    )}
                    {currentStep === 1 && (
                        <ModalStep>
                            <StepTitle>Fakturačné Údaje</StepTitle>
                            <Input
                                type="text"
                                placeholder="Celé Meno"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                className={missingFields.fullName ? 'input-error' : ''}
                            />
                            <Input
                                type="text"
                                placeholder="Adresa"
                                value={streetAddress}
                                onChange={(e) => setStreetAddress(e.target.value)}
                                className={missingFields.streetAddress ? 'input-error' : ''}
                            />
                            <Input
                                type="text"
                                placeholder="PSČ"
                                value={postcode}
                                onChange={(e) => setPostcode(e.target.value)}
                                className={missingFields.postcode ? 'input-error' : ''}
                            />
                            <Input
                                type="text"
                                placeholder="Mesto"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                className={missingFields.city ? 'input-error' : ''}
                            />
                            <Input
                                type="text"
                                placeholder="Krajina"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                className={missingFields.country ? 'input-error' : ''}
                            />
                            {showMissingFieldsMessage && <ErrorMessage>Vyplňte všetky položky</ErrorMessage>}
                        </ModalStep>
                    )}
                    {currentStep === 2 && (
                        <ModalStep>
                    {/* Centered Content */}
                    <CenteredContent>
                        <StepTitle>Rekapitulácia</StepTitle>
                        <CenteredText>Premium Balíček</CenteredText>
                        <CenteredPrice>Celková cena: {totalPrice} EUR</CenteredPrice>
                    </CenteredContent>
                    <Divider />
                </ModalStep>
                    )}
                    {currentStep === 3 && (
                        <ModalStep>
                            <StepTitle>Platba</StepTitle>
                            <PaymentElement />
                        </ModalStep>
                    )}
                </ModalContent>
                <ModalFooter>
                    {currentStep > 0 && <Button onClick={handlePreviousStep}>Späť</Button>}
                    {currentStep < 3 ? (
                        <Button onClick={handleNextStep}>Ďalej</Button>
                    ) : (
                        <Button onClick={handleSubmit}>Dokončiť</Button>
                    )}
                </ModalFooter>
            </Modal>
        </Overlay>
    );
};

export default BuyPremium;
