import React, { useState, useEffect, useRef } from 'react';
import api from "../api/axiosInstance";

interface LazyImageProps {
    postId: number;
    thumbnailSrc?: string;
    alt: string;
}

const LazyImage: React.FC<LazyImageProps> = ({ postId, thumbnailSrc, alt }) => {
    const [isInView, setIsInView] = useState(false);
    const [loadedSrc, setLoadedSrc] = useState<string | undefined>(thumbnailSrc);
    const imageRef = useRef<HTMLImageElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setIsInView(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );

        if (imageRef.current) {
            observer.observe(imageRef.current);
        }

        return () => {
            if (imageRef.current) {
                observer.unobserve(imageRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (isInView && loadedSrc === thumbnailSrc) {
            // Fetch the full image when in view
            const fetchFullImage = async () => {
                try {
                    const response = await api.get(`/posts/file/${postId}`, { responseType: 'arraybuffer' });
                    const imageBlob = new Blob([response.data], { type: response.headers['content-type'] });
                    const imageUrl = URL.createObjectURL(imageBlob);
                    setLoadedSrc(imageUrl);
                } catch (error) {
                    console.error('Error fetching full image:', error);
                }
            };

            fetchFullImage();
        }
    }, [isInView, postId, thumbnailSrc, loadedSrc]);

    return (
        <img
            ref={imageRef}
            src={loadedSrc}
            alt={alt}
            style={{ width: '100%', height: 'auto', transition: 'opacity 0.5s ease-in-out' }}
        />
    );
};

export default LazyImage;
