import React, { useState, useEffect, FormEvent } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FaChevronDown, FaChevronUp, FaUserCircle, FaTrashAlt } from 'react-icons/fa';
import api from "../api/axiosInstance"; // Adjust the import path as necessary
import { RootState } from '../store/store';

interface CommentSectionProps {
    postId: number;
    commentsCount: number;
    autoLoad?: boolean; // Optional prop, defaults to false if not provided
}

interface CommentDto {
    id: number;
    comment: string;
    commentedDate: string;
    fkPost: number;
    fullName: string;
}

const CommentsContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    border-top: 1px solid #ddd;
`;

const CommentToggle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
`;

const CommentToggleText = styled.span`
    font-weight: bold;
    color: #343434;
`;

const CommentList = styled.div`
    margin-top: 10px;
`;

const Comment = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    justify-content: space-between;
`;

const Avatar = styled.div`
    margin-right: 10px;
    font-size: 2em;
    color: #3ada5a;
`;

const CommentContent = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    max-width: 90%;
    word-wrap: break-word;
`;

const CommentAuthor = styled.span`
    font-weight: bold;
    margin-bottom: 5px;
`;

const CommentText = styled.p`
    margin: 0;
    font-size: 0.9em;
`;

const AddCommentForm = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;

const TextArea = styled.textarea`
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ddd;
`;

const SubmitButton = styled.button`
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`;

const DeleteButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    color: red;

    &:hover {
        color: darkred;
    }
`;

const CommentSection: React.FC<CommentSectionProps> = ({ postId, commentsCount, autoLoad = false }) => {
    const [comments, setComments] = useState<CommentDto[]>([]);
    const [isExpanded, setIsExpanded] = useState(autoLoad); // Initialize based on autoLoad
    const [newComment, setNewComment] = useState('');

    const user = useSelector((state: RootState) => state.auth.user);
    const fullName = user ? user.fullName : 'Guest';

    useEffect(() => {
        if (isExpanded || autoLoad) {
            loadComments();
        }
    }, [isExpanded, autoLoad]);

    const loadComments = async () => {
        try {
            const response = await api.get<CommentDto[]>(`/comment/all/${postId}`);
            setComments(response.data);
        } catch (error) {
            console.error('Error loading comments:', error);
        }
    };

    const handleAddComment = async (e: FormEvent) => {
        e.preventDefault();
        const commentData = {
            id: null,
            comment: newComment,
            commentedDate: new Date().toISOString(),
            fkPost: postId,
            fkUser: user ? user.id : null,
            fullName,
        };

        try {
            const response = await api.post('/comment', commentData);
            setComments([...comments, response.data]);
            setNewComment('');
            loadComments();
        } catch (error) {
            console.error('Error posting comment:', error);
        }
    };

    const handleDeleteComment = async (commentId: number) => {
        try {
            await api.delete(`/comment/${commentId}`);
            setComments(comments.filter(comment => comment.id !== commentId));
        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    const toggleComments = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <CommentsContainer>
            <CommentToggle onClick={toggleComments}>
                <CommentToggleText>Komentáre ({commentsCount})</CommentToggleText>
                {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
            </CommentToggle>
            {isExpanded && (
                <>
                    <CommentList>
                        {comments.map((comment) => (
                            <Comment key={comment.id}>
                                <div style={{ display: 'flex' }}>
                                    <Avatar>
                                        <FaUserCircle className="icon-gradient" />
                                    </Avatar>
                                    <CommentContent>
                                        <CommentAuthor>{comment.fullName}</CommentAuthor>
                                        <CommentText>{comment.comment}</CommentText>
                                    </CommentContent>
                                </div>
                                {(user && (user.fullName === comment.fullName || user.isAdmin)) && (
                                    <DeleteButton onClick={() => handleDeleteComment(comment.id)}>
                                        <FaTrashAlt />
                                    </DeleteButton>
                                )}
                            </Comment>
                        ))}
                    </CommentList>
                    <AddCommentForm onSubmit={handleAddComment}>
                        <TextArea
                            placeholder="Napíšte komentár..."
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                        />
                        <SubmitButton className="btn-gradient" type="submit">PRIDAŤ</SubmitButton>
                    </AddCommentForm>
                </>
            )}
        </CommentsContainer>
    );
};

export default CommentSection;
