import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../reducers/authSlice';

const loadState = () => {
    try {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        if (accessToken && refreshToken && user) {
            return {
                auth: {
                    accessToken,
                    refreshToken,
                    isAuthenticated: true,
                    user,
                },
            };
        }
    } catch (e) {
        console.error('Could not load state', e);
    }
    return undefined;
};

const persistedState = loadState();

export const store = configureStore({
    reducer: {
        auth: authReducer,
    },
    preloadedState: persistedState,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
