import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import api from "../api/axiosInstance";

// Styled components for the FAQ and form
const FAQContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(245, 102, 7, 0.3);
`;

const FAQHeading = styled.h2`
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
`;

const FAQList = styled.ul`
    list-style: none;
    padding: 0;
    width: 100%;
`;

const FAQItem = styled.li`
    margin-bottom: 20px;
`;

const Question = styled.strong`
    display: block;
    font-size: 1.1em;
    margin-bottom: 10px;
`;

const Answer = styled.p`
    font-size: 0.9em;
    white-space: pre-wrap;
    text-align: left;
`;

const FormContainer = styled.div`
    width: 100%;
    margin-top: 30px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 10px;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
`;

const TextArea = styled.textarea`
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
`;

const Button = styled.button`
    padding: 10px 15px;
    background-color: #f56607;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #e55906;
    }
`;

// FAQ Component with email form
const FAQ: React.FC = () => {
    const [formData, setFormData] = useState({
        subject: "",
        messageBody: "",
        senderEmail: "",
    });

    const [status, setStatus] = useState("");

    const faqs = [
        {
            question: "Ako si nainštalujem aplikáciu?",
            answer: "Aplikáciu si môžete stiahnuť z App Store alebo Google Play.",
        },
        {
            question: "Je používanie aplikácie zdarma?",
            answer: "Áno, aplikácia je zdarma, ale niektoré funkcie môžu byť spoplatnené.",
        },
        {
            question: "Ako môžem kontaktovať zákaznícku podporu?",
            answer: "Môžete nás kontaktovať cez náš kontaktný formulár alebo na instagrame @goly_esa_podcast .",
        },
        {
            question: "Ako si môžem resetovať heslo?",
            answer: "V aplikácii prejdite na stránku 'Nastavenia' a kliknite na možnosť 'Zabudnuté heslo'.",
        },
    ];

    // Handle input changes for the form
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setStatus("Odosielam...");

        try {
            // Konštrukcia URLSearchParams pre odoslanie údajov ako query parametre
            const params = new URLSearchParams();
            params.append("subject", formData.subject);
            params.append("messageBody", formData.messageBody);
            params.append("senderEmail", formData.senderEmail);

            // Odoslanie POST požiadavky na backend
            const response = await api.post(
                "/account/send-email-to",
                params,
                { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
            );

            if (response.status === 200) {
                setStatus("Email bol úspešne odoslaný!");
                setFormData({ subject: "", messageBody: "", senderEmail: "" }); // Resetovanie formulára
            } else {
                setStatus("Nepodarilo sa odoslať email.");
            }
        } catch (error) {
            console.error("Chyba pri odosielaní emailu:", error);
            setStatus("Nastala chyba pri odosielaní emailu.");
        }
    };


    return (
        <FAQContainer>
            <FAQHeading>Často kladené otázky (FAQ)</FAQHeading>
            <FAQList>
                {faqs.map((faq, index) => (
                    <FAQItem key={index}>
                        <Question>{faq.question}</Question>
                        <Answer>{faq.answer}</Answer>
                    </FAQItem>
                ))}
            </FAQList>

            {/* Email Form */}
            <FormContainer>
                <h2>Kontaktujte nás</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <Label>Vaša emailová adresa:</Label>
                        <Input
                            type="email"
                            name="senderEmail"
                            value={formData.senderEmail}
                            onChange={handleChange}
                            required
                            placeholder="Zadajte vašu emailovú adresu"
                        />
                    </div>
                    <div>
                        <Label>Predmet:</Label>
                        <Input
                            type="text"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                            placeholder="Zadajte predmet emailu"
                        />
                    </div>
                    <div>
                        <Label>Správa:</Label>
                        <TextArea
                            name="messageBody"
                            value={formData.messageBody}
                            onChange={handleChange}
                            required
                            placeholder="Napíšte vašu správu"
                            rows={5}
                        />
                    </div>
                    <Button type="submit">Odoslať Email</Button>
                </form>
                <p style={{ marginTop: "20px", color: "#333" }}>{status}</p>
            </FormContainer>
        </FAQContainer>
    );
};

export default FAQ;
