import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import api from "../api/axiosInstance";

const Container = styled.div`
    padding: 20px;
    position: relative;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const BackButton = styled.button`
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
`;

const Heading = styled.h2`
    margin: 0;
    font-size: 24px;
`;

const Label = styled.label`
    margin-bottom: 10px;
    display: block;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
`;

const SaveButton = styled.button`
    background-color: #242526;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;

    &:hover {
        background-color: #3a3b3c;
    }
`;
const ChangeBillingDetails: React.FC = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.auth.user);

    const [fullName, setFullName] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [postcode, setPostcode] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [missingFields, setMissingFields] = useState({
        fullName: false,
        streetAddress: false,
        postcode: false,
        city: false,
        country: false,
    });

    // Fetch user details when the component mounts
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await api.get(`/account/${user?.id}`);
                const { fullName, streetAddress, zipcode, city, country } = response.data;

                // Prefill the form with fetched user details
                setFullName(fullName);
                setStreetAddress(streetAddress);
                setPostcode(zipcode);
                setCity(city);
                setCountry(country);
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };

        fetchUserDetails();
    }, []);

    const handleSave = async () => {
        if (!fullName || !streetAddress || !postcode || !city || !country) {
            setMissingFields({
                fullName: !fullName,
                streetAddress: !streetAddress,
                postcode: !postcode,
                city: !city,
                country: !country,
            });
            return;
        }

        try {
            await api.put(`/account/${user?.id}`, {
                fullname: fullName,
                streetAddress,
                zipcode: postcode,
                city,
                country,
            });
            alert('Zmena údajov bola úspešná');
            navigate(-1); // Go back to the previous page
        } catch (error) {
            console.error('Error updating user details:', error);
            alert('Failed to update details. Please try again.');
        }
    };

    return (
        <Container>
            <Header>
                <BackButton onClick={() => navigate(-1)}>&larr;</BackButton>
                <Heading>Zmeniť fakturačné údaje</Heading>
            </Header>

            <Label>Celé Meno</Label>
            <Input
                type="text"
                placeholder="Celé Meno"
                value={fullName}
                onChange={(e) => {
                    setFullName(e.target.value);
                    setMissingFields({ ...missingFields, fullName: false });
                }}
                className={missingFields.fullName ? 'input-error' : ''}
            />

            <Label>Adresa</Label>
            <Input
                type="text"
                placeholder="Adresa"
                value={streetAddress}
                onChange={(e) => {
                    setStreetAddress(e.target.value);
                    setMissingFields({ ...missingFields, streetAddress: false });
                }}
                className={missingFields.streetAddress ? 'input-error' : ''}
            />

            <Label>PSČ</Label>
            <Input
                type="text"
                placeholder="PSČ"
                value={postcode}
                onChange={(e) => {
                    setPostcode(e.target.value);
                    setMissingFields({ ...missingFields, postcode: false });
                }}
                className={missingFields.postcode ? 'input-error' : ''}
            />

            <Label>Mesto</Label>
            <Input
                type="text"
                placeholder="Mesto"
                value={city}
                onChange={(e) => {
                    setCity(e.target.value);
                    setMissingFields({ ...missingFields, city: false });
                }}
                className={missingFields.city ? 'input-error' : ''}
            />

            <Label>Krajina</Label>
            <Input
                type="text"
                placeholder="Krajina"
                value={country}
                onChange={(e) => {
                    setCountry(e.target.value);
                    setMissingFields({ ...missingFields, country: false });
                }}
                className={missingFields.country ? 'input-error' : ''}
            />

            <SaveButton onClick={handleSave}>Uložiť</SaveButton>
        </Container>
    );
};

export default ChangeBillingDetails;
